import React from "react";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useBodyScrollLock } from "../../hooks/useBodyScrollLock";

import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import TutorialComponent from "./TutorialComponent";
import MotionPage from "../common/layout/MotionPage";

type Props = {};

function Tutorial(props: Props) {
  useInvocationCheck();
  useBodyScrollLock();

  return (
    <MotionPage className="h-full">
      <OrientationLock>
        <TutorialComponent />
      </OrientationLock>
    </MotionPage>
  );
}

export default Tutorial;
