import { Transition } from "@headlessui/react";
import { ConnectivityResponse, ConnectivityStatus, PrismaSDK } from "@prismadelabs/prismaid";
import React from "react";
import { Component } from "react";
import PositionedScaledImage from "../common/components/PositionedScaledImage";
import ScaledImage from "../common/components/ScaledImage";
import SDKSingleton from "./SDK";
import { SwipeData, SwipeResult } from "./SwipeData";

import frame from "./img/card@2x.png";
import touchPrint from "./img/touch_fingerabdruck@2x.png";
import pfeilTop from "./img/swipe-top@2x.png";
import pfeilBottom from "./img/swipe-bottom@2x.png";
import pfeilLinie from "./img/swipe-linie_lang@2x.png";
import dot from "./img/swipe-dot@2x.png";
import markerTop from "./img/swipe-marker@2x.png";
import markerBottom from "./img/swipe-marker@2x.png";
import swiper from "./img/swipe-escalator@2x.png";

import success from "../../assets/sounds/success.mp3";
import error from "../../assets/sounds/error.mp3";
import anime from "animejs";
import Sound from "../common/sound/Sound";
import i18n from "../../i18n";
import classNames from "classnames";

type Props = {
  scaleFactor: number;
  navigate: any;
};

type States = {
  // redirect: any;
  showDot: boolean;
  showRedAlert: boolean;
  showGreenAlert: boolean;
  errorCount: number;
};

class SwipeField extends Component<Props, States> {
  sdk: PrismaSDK;
  // sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
  successSound: Sound;
  errorSound: Sound;

  scaleFactor: number;

  constructor(props: any) {
    super(props);

    this.sdk = new PrismaSDK(
      process.env.REACT_APP_SDK_API_KEY!,
      // FIXME: remove dev-url
      "https://api-dev.prismade.net/prismaid"
    );
    this.sdk.setTwoFingerHoldingMode(true);

    this.successSound = new Sound(success);
    this.errorSound = new Sound(error);

    this.state = {
      // redirect: null,
      showDot: false,
      showRedAlert: false,
      showGreenAlert: false,
      errorCount: 0,
    };

    this.scaleFactor = this.props.scaleFactor;

    SwipeData.update((s) => {
      s.networkStatus = ConnectivityStatus.ok;
    });
  }

  componentWillUnmount() {
    // fixes warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    this.sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);

      SwipeData.update((s) => {
        s.progress = response.progress;
      });
    });

    this.sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      SwipeData.update((s) => {
        s.networkStatus = response.status;
      });
    });

    this.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) nextScreen:", response.rawData);

      // if (this.state.redirect) {
      //   return;
      // }

      this.flashGreenAlert();
      this.successSound.play();

      setTimeout(() => {
        this.setState({ errorCount: 0 });
        this.props.navigate("/success", { state: {}, replace: true });
        // this.setState({ redirect: "/success" });
      }, 500);
    });

    this.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());

      // if (this.state.redirect) {
      //   return;
      // }

      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      if (this.state.errorCount >= 4) {
        this.flashRedAlert();
        this.errorSound.play();
        this.setState({ errorCount: 0 });
        this.props.navigate("/failure", { state: {}, replace: true });
        // this.setState({ redirect: "/failure" });
      } else {
        this.flashRedAlert();

        if (response.hints[0]) {
          this.updateMessages({
            title: i18n.t("swipe:swipe.title"),
            message: i18n.t("swipe:" + response.hints[0].code),
          });
        }

        this.setState({
          errorCount: this.state.errorCount + 1,
        });
      }
    });

    this.sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          this.setState({ showDot: true });

          SwipeData.update((s) => {
            if (s.count === 0) {
              this.updateMessages({
                title: i18n.t("swipe:swipe.title"),
                message: i18n.t("swipe:swipe.body"),
              });
            }
          });

          break;
        case "changed":
          SwipeData.update((s) => {
            if (s.showWarningBackground) {
              s.showWarningBackground = false;
            }
          });
          break;
        case "complete":
          this.setState({ showDot: false });

          SwipeData.update((s) => {
            s.progress = 0;

            if (s.showWarningBackground) {
              s.showWarningBackground = false;
              s.showInteractiveHelp = true;

              this.updateMessages({
                title: i18n.t("swipe:swipe.title"),
                message: i18n.t("swipe:swipe.body"),
              });
            }
          });
          break;

        default:
          break;
      }
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      console.log("attaching sdk...");
      this.sdk.attachToElement(screen);
    }

    setTimeout(() => {
      this.startDemoAnimation();
    }, 1000);
  }

  updateMessages = (result: SwipeResult) => {
    SwipeData.update((s) => {
      let tmp = s.swipeResults;
      tmp.push({ ...result });
      s.swipeResults = tmp;
      s.count++;
    });

    // set visibility here to allow for transitions
    SwipeData.update((s) => {
      let tmp = s.swipeResults;
      // show last message
      if (tmp.length > 0) {
        tmp[tmp.length - 1].shouldRender = true;
      }
      // hide secondToLast message
      if (tmp.length > 1) {
        tmp[tmp.length - 2].shouldRender = false;
      }
      s.swipeResults = tmp;
    });
  };

  flashRedAlert = () => {
    this.setState({ showRedAlert: true });

    setTimeout(() => {
      this.setState({ showRedAlert: false });
    }, 155);
  };

  flashGreenAlert = () => {
    this.setState({ showGreenAlert: true });

    setTimeout(() => {
      this.setState({ showGreenAlert: false });
    }, 155);
  };

  startDemoAnimation = () => {
    anime({
      targets: "#hologram",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#hologram",
      scale: 1.8,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    anime({
      targets: "#edge",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#edge",
      scale: 1.2,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    setTimeout(() => {
      anime({
        targets: "#edge",
        opacity: 0.5,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#edge",
            opacity: 1.0,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 2000);

    setTimeout(() => {
      anime({
        targets: "#touchPrint",
        opacity: 0.6,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#touchPrint",
            opacity: 0.4,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 1000);

    setTimeout(() => {
      anime({
        targets: ["#pfeilBottom", "#pfeilTop", "#pfeilLinie"],
        opacity: 1.0,
        duration: 1000,
        easing: "linear",
      });
    }, 3000);

    setTimeout(() => {
      anime({
        targets: "#pfeilLinie",
        translateY: -(780 * this.scaleFactor),
        duration: 7000,
        loop: true,
        easing: "linear",
      });
    }, 4000);

    setTimeout(() => {
      anime({
        targets: "#swiper",
        translateY: 0 - (window.innerHeight + 800 * this.scaleFactor), // -(screenHeight+ swiperHeight)
        duration: 4000,
        loop: true,
        easing: "linear",
      });
    }, 6000);
  };

  render() {
    // if (this.state.redirect) {
    //   return <Navigate to={this.state.redirect} replace />;
    // }

    // let margin = 35;
    // if (window.innerHeight > 700) {
    //   margin = 100;
    // }
    // const marginBottom = margin * this.scaleFactor;

    // FIXME still not perfect :-/
    const cardOffset = "mb-[clamp(1rem,7%,4rem)]";

    return (
      <div id="swipeScreen" className="absolute top-0 left-0 w-screen h-full">
        <PositionedScaledImage
          src={frame}
          id="frame"
          alt="plasticFrame"
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={17}
          className={cardOffset}
          // verticalOffset={-70 - marginBottom}
        />
        <PositionedScaledImage
          src={touchPrint}
          id="touchPrint"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={-120}
          verticalOffset={-400}
          className={cardOffset}
          // verticalOffset={-470 - marginBottom}
          opacity={0}
        />

        <Transition
          show={this.state.showDot}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <PositionedScaledImage
            src={markerTop}
            id="markerTop"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={447}
            verticalOffset={-1096}
            className={cardOffset}
            // verticalOffset={-1166 - marginBottom}
          />

          <PositionedScaledImage
            src={markerBottom}
            id="markerBottom"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={447}
            verticalOffset={22}
            className={cardOffset}
            // verticalOffset={-48 - marginBottom}
          />

          <PositionedScaledImage
            src={dot}
            id="dot"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={444}
            verticalOffset={-1130}
            className={cardOffset}
            // verticalOffset={-1200 - marginBottom}
          />
        </Transition>

        <PositionedScaledImage
          src={pfeilTop}
          id="pfeilTop"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={447}
          verticalOffset={-925}
          className={cardOffset}
          // verticalOffset={-995 - marginBottom}
          opacity={0}
        />

        <div
          className={classNames("absolute overflow-hidden", cardOffset)}
          style={{
            width: this.scaleFactor * 90 + "px",
            height: this.scaleFactor * 770 + "px",
            left: this.scaleFactor * 448 + "px",
            bottom: this.scaleFactor * 145 + "px",
            // bottom: this.scaleFactor * 215 + marginBottom * this.scaleFactor + "px",
          }}
        >
          <ScaledImage src={pfeilLinie} id="pfeilLinie" alt="" className="top-0 " opacity={0} />
        </div>

        <PositionedScaledImage
          src={pfeilBottom}
          id="pfeilBottom"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={447}
          verticalOffset={-55}
          className={cardOffset}
          // verticalOffset={-125 - marginBottom}
          opacity={0}
        />

        <PositionedScaledImage
          src={swiper}
          id="swiper"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={403}
          className={cardOffset}
          verticalOffset={870}
        />

        <Transition
          show={this.state.showRedAlert}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="h-full"
        >
          <div id="redAlert" className="h-full bg-opacity-75 bg-status-red" />
        </Transition>

        <Transition
          show={this.state.showGreenAlert}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="h-full"
        >
          <div id="greenAlert" className="h-full bg-opacity-75 bg-status-green" />
        </Transition>
      </div>
    );
  }
}

export default SwipeField as any;
